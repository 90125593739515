import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.div.attrs({
})`
display: flex;
flex-direction: column;
justify-content: space-around;
width: 20rem;
height: 7rem;
text-align: center;
left: 50%;
bottom: 0;
`

const clientUrl = process.env.REACT_APP_CLIENT_URL

const Logo = () => {
  return (
    <StyledLogo className="container-fluid">
      <img className='login-logo' src={clientUrl + 'logo-tagline.png'} alt="logo-tagline"/>
    </StyledLogo>
  )
}

export default Logo;