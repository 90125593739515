import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import CookieService from '../../api/CookieService'

const Menu = ({ open }) => {

  const role = CookieService.getRoleFromSession()

  return (
    <StyledMenu open={open}>
      <a href="/dashboard" style={(window.location.pathname.includes("/dashboard"))?{color: "#343078", paddingTop:'24px'}:{paddingTop:'24px'}}>
        Dashboard
      </a>
      {
        (role === 'admin') && <a href="/users/list" style={(window.location.pathname.includes("/users"))?{color: "#343078"}:{}}>
          Account Management
        </a>
      }
      {
        (role === 'admin') && <a href="/crop-setting/select-plant" style={(window.location.pathname.includes("/crop-setting"))?{color: "#343078"}:{}}>
          Crop Settings
        </a>
      }
      <a href="/crops/list" style={(window.location.pathname.includes("/crops"))?{color: "#343078"}:{}}>
        View Crop
      </a>
      {
        (role === 'admin') && <a href="/plants/list" style={(window.location.pathname.includes("/plants"))?{color: "#343078"}:{}}>
          Plant Database
        </a>
      }
    </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
